import {
  RoleViewModel_default
} from "./chunk-IZPGLWFT.js";
import {
  RoleStore
} from "./chunk-IVGRCTQI.js";
import {
  MemberViewModel_default
} from "./chunk-UUCAFOTX.js";
import {
  MemberStore
} from "./chunk-EBEDYFR7.js";
import {
  MemberRoleViewModelContext
} from "./chunk-N5PAYPRJ.js";
import {
  renderingGroupFieldHandler
} from "./chunk-TCKGDJM4.js";
import {
  FORM_FIELD_TYPE
} from "./chunk-IIJXEE2D.js";
import {
  __publicField
} from "./chunk-JCO6WRHX.js";

// src/pages/MemberRole/MemberRoleEdit/Component/MemberRoleInformation.tsx
import { MEMBER_ROLE_FIELD } from "aesirx-lib";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";
var roleStore = new RoleStore();
var roleViewModel = new RoleViewModel_default(roleStore);
var memberStore = new MemberStore();
var memberViewModel = new MemberViewModel_default(memberStore);
var _a;
var MemberRoleInformation = observer(
  (_a = class extends Component {
    constructor(props) {
      super(props);
      __publicField(this, "viewModel", null);
      __publicField(this, "roleListViewModel");
      __publicField(this, "memberListViewModel");
      this.roleListViewModel = roleViewModel.roleListViewModel;
      this.memberListViewModel = memberViewModel.memberListViewModel;
    }
    componentDidMount() {
      const fetchData = async () => {
        await this.roleListViewModel.initializeAllData();
        await this.memberListViewModel.initializeAllData();
      };
      fetchData();
    }
    render() {
      this.viewModel = this.context.model.memberRoleDetailViewModel;
      const { t, validator } = this.props;
      const generateFormSetting = [
        {
          fields: [
            {
              label: t("txt_roles"),
              key: MEMBER_ROLE_FIELD.ROLE_ID,
              type: FORM_FIELD_TYPE.SELECTION,
              getValueSelected: this.viewModel.memberRoleDetailViewModel.formPropsData[MEMBER_ROLE_FIELD.ROLE_ID] ? {
                label: this.roleListViewModel?.successResponse?.listRolesWithoutPagination?.find(
                  (x) => x?.value.toString() === this.viewModel.memberRoleDetailViewModel.formPropsData[MEMBER_ROLE_FIELD.ROLE_ID]?.toString()
                )?.label,
                value: this.viewModel.memberRoleDetailViewModel.formPropsData[MEMBER_ROLE_FIELD.ROLE_ID]
              } : null,
              getDataSelectOptions: this.roleListViewModel?.successResponse?.listRolesWithoutPagination?.length ? this.roleListViewModel?.successResponse?.listRolesWithoutPagination?.map(
                (item) => {
                  return {
                    label: item?.label,
                    value: item?.value,
                    type: item?.type
                  };
                }
              ) : [],
              handleChange: (data) => {
                this.viewModel.handleFormPropsData(MEMBER_ROLE_FIELD.ROLE_ID, data?.value ?? 0);
                if (data?.type === "number") {
                  this.setState({ isNumber: true });
                  this.viewModel.handleFormPropsData(MEMBER_ROLE_FIELD.VALUE, "");
                } else {
                  this.setState({ isNumber: false });
                }
              },
              required: true,
              validation: "required",
              placeholder: t("txt_roles"),
              className: "col-lg-12"
            },
            {
              label: t("txt_members"),
              key: MEMBER_ROLE_FIELD.MEMBER_ID,
              type: FORM_FIELD_TYPE.SELECTION,
              getValueSelected: this.viewModel.memberRoleDetailViewModel.formPropsData[MEMBER_ROLE_FIELD.MEMBER_ID] ? {
                label: this.memberListViewModel?.successResponse?.listMembersWithoutPagination?.find(
                  (x) => x?.value.toString() === this.viewModel.memberRoleDetailViewModel.formPropsData[MEMBER_ROLE_FIELD.MEMBER_ID]?.toString()
                )?.label,
                value: this.viewModel.memberRoleDetailViewModel.formPropsData[MEMBER_ROLE_FIELD.MEMBER_ID]
              } : null,
              getDataSelectOptions: this.memberListViewModel?.successResponse?.listMembersWithoutPagination?.length ? this.memberListViewModel?.successResponse?.listMembersWithoutPagination?.map(
                (item) => {
                  return {
                    label: item?.label,
                    value: item?.value,
                    type: item?.type
                  };
                }
              ) : [],
              handleChange: (data) => {
                this.viewModel.handleFormPropsData(MEMBER_ROLE_FIELD.MEMBER_ID, data?.value ?? 0);
                if (data?.type === "number") {
                  this.setState({ isNumber: true });
                  this.viewModel.handleFormPropsData(MEMBER_ROLE_FIELD.VALUE, "");
                } else {
                  this.setState({ isNumber: false });
                }
              },
              required: true,
              validation: "required",
              placeholder: t("txt_members"),
              className: "col-lg-12"
            }
          ]
        }
      ];
      return /* @__PURE__ */ React.createElement("div", { className: "p-24 bg-white rounded-1 shadow-sm h-100 mt-24" }, Object.keys(generateFormSetting).map((groupIndex) => {
        return [...Array(generateFormSetting[groupIndex])].map((group) => {
          return renderingGroupFieldHandler(group, validator);
        });
      }).reduce((arr, el) => {
        return arr.concat(el);
      }, []));
    }
  }, __publicField(_a, "contextType", MemberRoleViewModelContext), _a)
);
var MemberRoleInformation_default = withTranslation()(MemberRoleInformation);

export {
  MemberRoleInformation_default
};
